import React, { useState, FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import "./style.css";
import Modal from "../modal/modal"

const Info_Bearing = () => {
  const [modalActive, setModalActive] = useState<boolean>(false);
    return (
<div className={modalActive ? "main_content active" : "main_content"}>
  <h1>Как подобрать аналог подшипника:</h1>
  <p>При выборе подшипника взамен оригинального, нужно углубленно изучить характеристики имеющегося. Это можно сделать изучив маркировку  нанесенную на подшипник. Основными параметрами для поиска аналога будут:<br/>
- тип подшипника: принцип работы (скольжение, качение). В зависимости от типа работы, может быть шариковым, роликовым или игольчатым.<br/>
- вид воспринимаемой нагрузки: радиальная, радиально-упорная или упорная.<br/>
- частота вращения.<br/>
- для некоторых узлов и механизмов критическим критерием является класс точности подшипника.<br/>
- грузоподъемность.<br/>
- температура отпуска колец.<br/>
- открытость/закрытость подшипника.<br/>
<strong> 
Не знаете как подобрать подшипник? Отправьте нам запрос по форме. В кратчайшие сроки наш менеджер Вам поможет.
</strong>
</p>
<button onClick={() => setModalActive(true)}>Отправить запрос</button>
<div className='backdrop'> 
<div className='footer'> 
                <div className='footer__list'>
                <div className='footer__item'>
                <a href="/about"><h3>О нас</h3></a>
                </div>
                <div className='footer__item'>
                <a href="/about"><h3>Контакты</h3></a>
                </div>
                <div className='footer__item'>
                <h3>Информация о подшипниках</h3>
                </div>
                </div>
                </div>
</div>
<Modal active={modalActive} setActive={setModalActive} />
</div>
    );
  };
  
  export default Info_Bearing;
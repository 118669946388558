import React, { useState, useMemo, FC, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, useParams, Routes } from 'react-router-dom';
import {CatalogInterface} from "../../interfaces/ApiInterfaces" 
import Card from "../card/Card";
import './style.css'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
let workers: CatalogInterface[] = [];
let countPages: number = 1;
    interface ITableProps {
      list: CatalogInterface[]
    }


    interface FieldValues {
        searchStr?: string;
        minInnerDiameter?: string;
        maxInnerDiameter?: string;
        minOutterDiameter?: string;
        maxOutterDiameter?: string;
        minWidth?: string;
        maxWidth?: string;
      }

      const apiOFF = () => Store.addNotification({
        title: "Произошла ошибка",
        message: "Мы уже работаем над исправлением",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        hasBeenRemoved: false,
        // dismiss: {
        //   duration: 5000,
        //   onScreen: true
        // }
      });
      const not_found_error = () => Store.addNotification({
        title: "Ничего не найдено",
        message: "Проверьте запрос на правильность",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        hasBeenRemoved: false,
        // dismiss: {
        //   duration: 5000,
        //   onScreen: true
        // }
      });

    const ProductButton = ({ productId } : {productId: number}) => {
      return (
        <Link to={`/product/${productId}`} target="_blank">
          <button>Открыть товар</button>
        </Link>
      );
    };

    const TableTest = () => {
      const handleButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
        },
        []
      );

    const [text, setFilter] = useState<string>();
    const [category, setCategory] = useState<number>(1);
    const [countPage, setCountPage] = useState<number>(countPages);
    const [apiUrl, setApiUrl] = useState<string>(`https://api.reliable-bearing.ru/category=${category}/pageCount=${countPage}`);
    const [fieldValues, setFieldValues] = useState<string[]>([]);
    const [workers, setWorkers] = useState<CatalogInterface[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [minDM, setMinDM] = useState<string>('');
    const [maxDM, setMaxDM] = useState<string>('');
    const [minOuterDM, setMinOuterDM] = useState<string>('');
    const [maxOuterDM, setMaxOuterDM] = useState<string>('');
    const [minWidth, setMinWidth] = useState<string>('');
    const [maxWidth, setMaxWidth] = useState<string>('');

    const changeCategory = ( categoryNumber: number) => {
      setCountPage(0);
      setApiUrl(`https://api.reliable-bearing.ru/category=${categoryNumber}/pageCount=${countPage}`)
    }

  const handleClick = () => {
    const fields = [searchText, minDM, maxDM, minOuterDM, maxOuterDM, minWidth, maxWidth];

    const formattedValues: FieldValues = {};

    fields.forEach((field, index) => {
      if (index === 0 && field !== "") {
        formattedValues.searchStr = field;
      } else if (index === 1 && field !== "") {
        formattedValues.minInnerDiameter = field;
      } else if (index === 2 && field !== "") {
        formattedValues.maxInnerDiameter = field;
      }
      else if (index === 3 && field !== "") {
        formattedValues.minOutterDiameter = field;
      } else if (index === 4 && field !== "") {
        formattedValues.maxOutterDiameter = field;
      }
      else if (index === 5 && field !== "") {
        formattedValues.minWidth = field;
      } else if (index === 6 && field !== "") {
        formattedValues.maxWidth = field;
      }
    });

    const joinedString = Object.entries(formattedValues)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    setFieldValues([...fieldValues, joinedString]);
    console.log("https://api.reliable-bearing.ru/search/pageCount=1?"+joinedString)
    setApiUrl("https://api.reliable-bearing.ru/search/pageCount=1?"+joinedString);
  };

  //
  useEffect(() => {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Данные не найдены');
        }
        return response.json();
      })
      .then((data: CatalogInterface[]) => setWorkers(data))
      .catch((error) => {
        if (error.message === 'Данные не найдены') {
          // Обработка ошибки 404
          not_found_error();
          console.error('Ошибка 404: Данные не найдены');
        } else {
          apiOFF();
          console.error('Произошла ошибка при загрузке данных:', error);
        }
      });

    return () => {};
  }, [countPage, apiUrl, category]);
      
      const rows = useMemo(() => {
        return workers.map((elem, index) => (
          <div className='item' key={index}>  
          <div className='image'>
            <img src={elem.urlPic} alt="" />
            </div>
            <div className='card'>
            <h1><span>Модель: </span>{elem.number}</h1>
            <p className='info'><span>Тип: </span>{elem.type}</p>
            <div className='parametrs'>
            <p className='info'><span>d,мм: </span>{elem.innerDiameter}</p>
            <p className='info'><span>D,мм: </span>{elem.outerDiameter}</p>
            </div>            <div className='parametrs'>

            <p className='info'><span>B,мм: </span>{elem.width}</p>
            <p className='info'><span>m,кг: </span>{elem.weight}</p>
            </div>
            <ProductButton productId={elem.id} />
            </div>
          </div>
      ));
      }, [workers]);

            return (<div className="catalog">
                <div className='left'>
                    <h1>Категории</h1>
                    <div className='category'> 
                    <a className="first__level">Подшипник качения</a>
                      <a className="second__level">Импортные</a>
                        <a className="three__level">Шариковые</a>
                                  <a className="fourl__level" onClick={() => changeCategory(1)}>Радиальные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(2)}>Сферические</a>
                                  <a className="fourl__level" onClick={() => changeCategory(3)}>Радиально-упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(4)}>Упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(5)} >Упорно-радиальные</a>

                        <a className="three__level">Роликовые</a>
                                  <a className="fourl__level" onClick={() => changeCategory(6)}>Радиальные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(7)} >Радиально-упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(8)}>Сферические</a>
                                  <a className="fourl__level" onClick={() => changeCategory(9)}>Игольчатые</a>
                                  <a className="fourl__level" onClick={() => changeCategory(10)}>Упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(11)}>Коннические</a>
                                  <a className="fourl__level" onClick={() => changeCategory(12)}>Цилиндрические</a>
                        <a className="three__level" onClick={() => changeCategory(13)}>Комбинированные</a>
                      <a className="second__level">Отечественные</a>
                          <a className="three__level">Шариковые</a>
                                  <a className="fourl__level" onClick={() => changeCategory(14)}>Радиальные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(15)}>Сферические</a>
                                  <a className="fourl__level" onClick={() => changeCategory(16)}>Радиально-упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(17)}>Упорные</a>
                          <a className="three__level">Роликовые</a>
                                  <a className="fourl__level" onClick={() => changeCategory(18)}>Радиальные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(19)}>Радиально-упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(20)}>Упорные</a>
                                  <a className="fourl__level" onClick={() => changeCategory(21)}>Сферические</a>
                                  <a className="fourl__level" onClick={() => changeCategory(22)}>Игольчатые</a>
                                  <a className="fourl__level" onClick={() => changeCategory(23)}>Коннические</a>
                                  <a className="fourl__level" onClick={() => changeCategory(24)}>Цилиндрические</a>
                          <a className="three__level" onClick={() => changeCategory(25)}>Комбинированные</a>
                    <a className="first__level">Подшипник скольжение</a>
                      <a className="second__level" onClick={() => changeCategory(26)}>Шарнирные</a>
                    </div>
                    <h1>Поиск</h1>
                    <div className='search'>
                    <span>Наименование</span>
                    <input type="text" value={text} placeholder="Наименование" onChange={(event) => setSearchText(event.target.value)}/>
                    <span>Диаметр внутренний (d), мм</span>
                    <div className='search__item'> 
                    <input type="text" value={text} placeholder="Минимальный" onChange={(event) => setMinDM(event.target.value)}/>
                    <p>-</p>
                    <input type="text" value={text} placeholder="Максимальный" onChange={(event) => setMaxDM(event.target.value)}/>
                    </div>
                    <span>Диаметр внешний (D), мм</span>
                    <div className='search__item'> 
                    <input type="text" value={text} placeholder="Минимальный" onChange={(event) => setMinOuterDM(event.target.value)}/>
                    <p>-</p>
                    <input type="text" value={text} placeholder="Максимальный" onChange={(event) => setMaxOuterDM(event.target.value)}/>
                    </div>
                    <span>Высота (H) </span>
                    <div className='search__item'> 
                    <input type="text" value={text} placeholder="Мин высота" onChange={(event) => setMinWidth(event.target.value)}/>
                    <p>-</p>
                    <input type="text" value={text} placeholder="Макс высота" onChange={(event) => setMaxWidth(event.target.value)}/>
                    </div>
                    </div>
                    <div className='search__accept'> 
                    <button  onClick={handleClick} className='search__accept'>Применить</button>
                    </div>
                    

                </div>

                <div className='right'> 
                <div className='items__list'>
                {rows}

                </div>




                <div className='buttons'> 
      <button className='pageSelect' onClick={() => {
        setCountPage(countPage - 1)
        setApiUrl(`https://api.reliable-bearing.ru/category=1/pageCount=${countPage}`);
      }}>≤ Назад</button>
      <button className='pageSelect' onClick={() => {
        setCountPage(countPage + 1)
        setApiUrl(`https://api.reliable-bearing.ru/category=1/pageCount=${countPage}`);
      }}>Вперед ≥</button></div>
                </div>


            </div>
            )
          
    }
    export default TableTest;
import React, { useState, FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import "./modal.css";
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

interface FieldValues {
  name?: string;
  phone?: string;
  email?: string;
  message?: string;
}


const Modal = ({active, setActive}: any) => { 

  const [text, setFilter] = useState<string>();
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const fields = [name, phone, email, message];
  

  const handleClick = () => {


    

    const formattedValues: FieldValues = {};

    fields.forEach((field, index) => {
      if (index === 0 && field !== "") {
        formattedValues.name = field;
      } else if (index === 1 && field !== "") {
        formattedValues.phone = field;
      } else if (index === 2 && field !== "") {
        formattedValues.email = field;
      }
      else if (index === 3 && field !== "") {
        formattedValues.message = field;
      }
    });

    if(name == "" || phone == "" || message == ""){
      return badNotification("Пожалуйста заполните все поля")
    }

    const joinedString = Object.entries(formattedValues)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    
    const replacedString = joinedString.replace("+", "%2B");

    setFieldValues([...fieldValues, replacedString]);
    sendData(replacedString)
    console.log("https://api.reliable-bearing.ru/feedback?"+replacedString)
    // setApiUrl("https://localhost:7209/search/pageCount=1?"+joinedString);
  };


  async function sendData(subject: string) {
    const url = 'https://api.reliable-bearing.ru/feedback?'+subject;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  
    if (response.ok) {
      const responseData = await response.json();
      console.log('Response data:', responseData);
      notification();
    } else {
      badNotification("Мы уже работаем над исправлением")
      console.error('Failed to send empty POST request');
    }
  }


  const badNotification = (message: string) => Store.addNotification({
    title: "Произошла ошибка",
    message: message,
    type: "danger",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });

  const notification = () => Store.addNotification({
    title: "Сообщение отправлено",
    message: "Мы скоро свяжемся с вами",
    type: "success",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });

    return (
    <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}> 
    <div className={active ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
      <h1 className='modal__text'>Уточнить цену</h1>
      <span className='textOver'>Имя</span>
      <input  value={text} className='inputs' type="text" placeholder="Имя" name="" id="" onChange={(event) => setName(event.target.value)} />
      <span className='textOver'>Почта</span>
      <input value={text} className='inputs' type="email" placeholder="E-mail" name="email" id="" onChange={(event) => setEmail(event.target.value)} />
      <span className='textOver'>Телефон</span>
      <input value={text} className='inputs' type="text" placeholder="Телефон" name="" id=""  onChange={(event) => setPhone(event.target.value)} />
      <span className='textOver'>Сообщение</span>
      <input value={text} className='inputs' type="text" name="" placeholder='Сообщение' id="" onChange={(event) => setMessage(event.target.value)} />

      <button className='send' onClick={handleClick}>отправить заявку</button>
      <h2>Мы свяжемся с вами в ближайшее время</h2>
    </div>
    </div>
    );
  };
  
  export default Modal;
import React, { useState, FC, useEffect, useCallback } from 'react';
import { CatalogInterface } from "./interfaces/ApiInterfaces"
import Table from "./componets/table/Table"
import Header from "./componets/header/Header"
import HeaderNew from "./componets/header/HeaderNew"
import About from "./componets/about/about"
import Delivery from "./componets/delivery/delivery"
import Info_Bearing from "./componets/infoBearing/info"
import { BrowserRouter as Router, Route, Link, useParams, Routes } from 'react-router-dom';
import Card from './componets/card/Card';
import Main from './componets/Main/Main';
import TableTest from './componets/table/Table2';
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

let workers: CatalogInterface[] = [];
let countPages: number = 1;
interface IPropsApp {
  ItemCatalog?: CatalogInterface[];
}
interface FieldValues {
  searchStr?: string;
  minInnerDiameter?: string;
  maxInnerDiameter?: string;
  minOutterDiameter?: string;
  maxOutterDiameter?: string;
  minWidth?: string;
  maxWidth?: string;
}


const App: FC<IPropsApp> = () => {
  const [category, setCategory] = useState<number>(1);
  const handleCategoryChange = (category: number) => {
    setCategory(category);
    setApiUrl(`https://localhost:7209/category=${category}/pageCount=${countPage}`)
  };
  const [workers, setWorkers] = useState<CatalogInterface[]>([]);
  const [countPage, setCountPage] = useState<number>(countPages);
  const [apiUrl, setApiUrl] = useState<string>(`https://localhost:7209/category=${category}/pageCount=${countPage}`);
  const [text, setFilter] = useState<string>();
  //SEARCH 
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [minDM, setMinDM] = useState<string>('');
  const [maxDM, setMaxDM] = useState<string>('');
  const [minOuterDM, setMinOuterDM] = useState<string>('');
  const [maxOuterDM, setMaxOuterDM] = useState<string>('');
  const [minWidth, setMinWidth] = useState<string>('');
  const [maxWidth, setMaxWidth] = useState<string>('');
  document.title = "Reliable-Bearing";
  const handleClick = () => {
    const fields = [searchText, minDM, maxDM, minOuterDM, maxOuterDM, minWidth, maxWidth];

    const formattedValues: FieldValues = {};

    fields.forEach((field, index) => {
      if (index === 0 && field !== "") {
        formattedValues.searchStr = field;
      } else if (index === 1 && field !== "") {
        formattedValues.minInnerDiameter = field;
      } else if (index === 2 && field !== "") {
        formattedValues.maxInnerDiameter = field;
      }
      else if (index === 3 && field !== "") {
        formattedValues.minOutterDiameter = field;
      } else if (index === 4 && field !== "") {
        formattedValues.maxOutterDiameter = field;
      }
      else if (index === 5 && field !== "") {
        formattedValues.minWidth = field;
      } else if (index === 6 && field !== "") {
        formattedValues.maxWidth = field;
      }
    });

    const joinedString = Object.entries(formattedValues)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    setFieldValues([...fieldValues, joinedString]);
    console.log("https://localhost:7209/search/pageCount=1?"+joinedString)
    setApiUrl("https://localhost:7209/search/pageCount=1?"+joinedString);
  };

  const notification = () => Store.addNotification({
    title: "Wonderful!",
    message: "Welcome!!!",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });


  return (
    <>
      {/* <Header onClick={handleCategoryChange}/> */}
      <HeaderNew />
      <ReactNotifications />
      
      <Router>
  <Routes>
    <Route path='*' element={<Main />} />
    <Route path='/about' element={<About />} />
    <Route path='/delivery' element={<Delivery />} />
    <Route path='/info' element={<Info_Bearing />} />
    <Route path='/table' element={<TableTest />} />
    <Route path="/product/:productId" element={<Card />} />
  </Routes>
</Router>
    </>
  );
}

export default App;   
import React, { useState, FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import "./style.css";
import {CardInterface} from "../../interfaces/ApiInterfaces" 
import Modal from "../modal/modal"
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

interface ITableProps {
    list: CardInterface[]
  }

const Card = () => {
    const { productId } = useParams();
    const [workers, setWorkers] = useState<CardInterface>();
    const apiUrl = `https://api.reliable-bearing.ru/cardId=${productId}`;
    const [modalActive, setModalActive] = useState<boolean>(false);

    const apiOFF = () => Store.addNotification({
      title: "Произошла ошибка",
      message: "Мы уже работаем над исправлением",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      hasBeenRemoved: false,
      // dismiss: {
      //   duration: 5000,
      //   onScreen: true
      // }
    });
    const not_found_error = () => Store.addNotification({
      title: "Ничего не найдено",
      message: "Проверьте запрос на правильность",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      hasBeenRemoved: false,
      // dismiss: {
      //   duration: 5000,
      //   onScreen: true
      // }
    });
  

    useEffect(() => {
        fetch(apiUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Данные не найдены');
            }
            return response.json();
          })
          .then((data: CardInterface) => setWorkers(data))
          .catch((error) => {
            if (error.message === 'Данные не найдены') {
              // Обработка ошибки 404
              not_found_error();
              console.error('Ошибка 404: Данные не найдены');
            } else {
              console.error('Произошла ошибка при загрузке данных:', error);
              apiOFF();
            }
          });
    
        return () => {};
      }, [apiUrl]);
  
    return (
      // <div>
      //   <h2>Страница товара</h2>
      //   <p>Товар с ID: {workers?.cardNumber}</p>
      //   <p>{workers?.brand}</p>
      //   <p>{workers?.description}</p>
      //   <p>{workers?.standart}</p>
      //   <img src={workers?.urlPic} alt="" />
      // </div>
<div className={modalActive ? "cardMain active" : "cardMain"}>
      <div className="cartTitle">Подшипник: <span>{workers?.cardNumber}</span></div>
      <div className="cardInfo">
        {/* <img src={require('./test_img.png')} alt=""/> */}
        <img src={workers?.urlPic} alt="" />
        <div className="cardParams">
          <p>Артикул: <span>{workers?.cardNumber}</span></p>
          <p>Стандарт: <span>{workers?.standart}</span></p>
          <p>Бренды: <span>{workers?.brand}</span></p>
          <p>Внутренний диаметр: <span>{workers?.innerDiameter}</span></p>
          <p>Наружный диаметр: <span>{workers?.outerDiameter}</span></p>
          <p>Ширина: <span>{workers?.width}</span></p>
          <p>Масса: <span>{workers?.weight}</span></p>
        </div>
        
      </div>
      <div className="cardDiscription">
          <p>Описание товара:</p>
          <span>{workers?.description}</span>
        </div>
      <div className="cardBtn">
        <p>Стоимость подшипника отправляется по запросу</p>
        <button onClick={() => setModalActive(true)}>Узнать цену</button>
      </div>
      <div className='info_warning'>
      <h1>Обратите внимание!</h1>
      <p>Если вы заинтересованы в покупке подшипника, узнайте у нас информацию о цене, наличии, сроках поставки, вариантах и аналогах. Для связи звоните по телефону: +7 (000) 00-00-00 или направляйте запрос на почту: mail.ru</p>
      </div>
      <Modal active={modalActive} setActive={setModalActive} /> 
  </div>
    );
  };
  
  export default Card;
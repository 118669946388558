import React, { useState, FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import "./style.css";

const Delivery = () => {
    return (
<div className='main_content'>
  <h1>Оплата и доставка</h1>
  <p> - безналичный расчет<br/>
- оплата наличными при получении на складе<br/>
- переводом через приложение Банка на карту<br/>
<strong>
Отправляем товар через транспортные компании: Деловые линии, ПЭК, СДЭК. Или любой другой по желанию клиента.
Доставка до транспортной компании – бесплатно.</strong>

</p>

<div className='backdrop'> 
<div className='footer'> 
                <div className='footer__list'>
                <div className='footer__item'>
                <a href="/about"><h3>О нас</h3></a>
                </div>
                <div className='footer__item'>
                <a href="/about"><h3>Контакты</h3></a>
                </div>
                <div className='footer__item'>
                <h3>Информация о подшипниках</h3>
                </div>
                </div>
                </div>
</div>
</div>
    );
  };
  
  export default Delivery;
import React, { useState, FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import "./style.css";

const About = () => {
    return (
<div className='main_content'>
  <h1>о компании</h1>
  <p>Наша компания является надежным и ответственным партнером. Мы ценим каждого клиента, поэтому диалог всегда нацелен на удовлетворение всех запросов. Огромный ассортимент предлагаемых подшипников (более 200.000 позиций). Соблюдение заявленных сроков поставки. Нацеленность на долгосрочное, взаимовыгодное сотрудничество. Скидки постоянным клиентам. Сохранены логистические цепочки с Европой для поставки подшипников при их отсутствии на складе.
    <h2>Контакты</h2>
    <div className='contacts'> 
    <p>Мы находимся по адресу: г. Смоленск, Рославльское шоссе, дом 11, офис 306</p>
    <span>Телефон: <strong> +7(920)333-55-60</strong></span>
    <span>Эл. адрес: <strong> zakaz-np-sml@mail.ru</strong></span>
    </div>
</p>
<div className='backdrop'> 
<div className='footer'> 
                <div className='footer__list'>
                <div className='footer__item'>
                <span>Узнать</span>
                <a href="/about"><h3>О нас</h3></a>
                </div>
                <div className='footer__item'>
                <span>Узнать</span>
                <a href="/about"><h3>Контакты</h3></a>
                </div>
                <div className='footer__item'>
                <span>Узнать</span>
                <h3>Информацию о подшипниках</h3>
                </div>
                </div>
                </div>
</div>
</div>
    );
  };
  
  export default About;
import React, { FC, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import logo from "./logo.png"
import './style.css'
import App from "../../App";
import photo__catalog from "./img/photo__catalog.png"
import car from "./img/car.png"

const Main = () => {
            return (<div className="main__main">
                <div className='poster__photo'> 
                <h1>Специалист по изготовлению и<br/>модификации подшипников</h1>
                </div>
                <div className='catalog__production'>
                    <h1>Каталог продукции</h1>
                    <div className='catalog__block'> 
                    <div className='catalog__block__left'>
                    <h1>Переход в каталог</h1>
                    <p>Здравствуйте! Если вы ищете подшипники, то вы попали по адресу! Позвольте мне провести вас через виртуальные двери в удивительный мир подшипников. Здесь вы найдете все, что нужно знать о различных типах подшипников, их функциях и применении. С графическими иллюстрациями и описаниями, я помогу вам разобраться в выборе подшипника, который будет соответствовать именно вашим потребностям.</p>
                    <button>ПЕРЕЙТИ</button>
                    </div>


                    <div className='catalog__block__right'>

                        <img src={photo__catalog} alt="" />
                    </div>
                    
                    </div>

                </div>

                <div className='examples'> 
                    <h1>Примеры использования наших подшипников</h1>
                    <div className='examples__photo'>
                        <div className='examples__photo__block'>
                        <p>Автомобиль</p>
                        <img src={require('./img/car.png')} alt="" />
                        </div>
                        <div className='examples__photo__block'>
                        <p>Промышленные машины</p>
                        <img src={require('./img/prom_car.png')} alt="" />
                        </div>
                        <div className='examples__photo__block'>
                        <p>Аэрокосмическая промышленность</p>
                        <img src={require('./img/plain.png')} alt="" />
                        </div>
                        <div className='examples__photo__block'>
                        <p>Железнодорожный транспорт</p>
                        <img src={require('./img/train.png')} alt="" />
                        </div>
                        <div className='examples__photo__block'>
                        <p>Строительное оборудование</p>
                        <img src={require('./img/construct.png')} alt="" />
                        </div>
                        <div className='examples__photo__block'>
                        <p>Ветряные генераторы</p>
                        <img src={require('./img/generators.png')} alt="" />
                        </div>
                    </div>
                    <div className='contact'>
                    
                    <div className="contact__left">
                        <h2>Мы всегда готовы помочь в выборе.</h2>
                        <p>Свяжитесь с нами</p>
                    </div>
                    <div className="contact__right">
                        <button>Свяжитесь с нами</button>
                    </div>
                    </div>
                </div>


                <div className='footer'> 
                <div className='footer__list'>
                <div className='footer__item'>
                <h3>15000</h3>
                <p>Довольных клиентов</p>
                </div>
                <div className='footer__item'>
                <h3>15000</h3>
                <p>Пропорциональная площадь</p>
                </div>
                <div className='footer__item'>
                <h3>15000</h3>
                <p>Пропорциональная площадь</p>
                </div>
                </div>
                </div>
            </div>)
          
    }
    export default Main;